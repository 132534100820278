.account-settings {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .container {
    margin-top: 30px;
    display: flex;
    gap: 30px;
    width: 1136px;
    flex-wrap: wrap;

    > .left {
      width: 340px;
    }

    > .right {
      width: 766px;

      .content-wrapper {
        background-color: white;

        .ant-tabs-nav-wrap {
          background-color: #f8f9fb;
          border-bottom: 1px solid #ededed;
          padding: 0 40px;
        }

        .ant-tabs-tab {
          font-weight: 500;
        }

        .ant-tabs-tabpane {
          padding: 20px 40px;
        }

        .title {
          font-size: 28px;
          font-weight: bold;
          padding: 40px;
          background-color: #F8F9FB;
          padding-bottom: 20px;
        }

        .content {
          .custom-row {
            display: flex;
            justify-content: space-between;
            gap: 40px;

            .ant-form-item {
              flex: 1;

              label {
                height: max-content;
              }
            }
          }
        }
      }
    }
  }
}

.main-mobile {
  .account-settings {
    .container {
      .left {
        display: none;
      }

      .right {
        .content-wrapper {
          button {
            font-size: 14px;
          }

          .title {
            padding: 40px 15px;
            padding-bottom: 20px;
          }

          .ant-tabs-nav-wrap {
            padding: 0 15px;
          }

          .content {
            .custom-row {
              gap: 10px;
              flex-direction: column;
              margin-bottom: 20px;

              > div {
                margin-bottom: 0;
              }

              .ant-form-item {
                label {
                  font-size: 14px;
                }
              }
            }
          }

          .ant-tabs-tabpane {
            padding: 20px 20px;
          }
        }
      }
    }
  }
}
.bottom-navigation-bar {
  width: 100%;
  background-color: white;
  position: fixed;
  bottom: 0;
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: space-evenly;
  z-index: 3;
  box-shadow: 0 0 1px #0000001a;

  a {
    text-decoration: none;

    button {
      border: none;
      box-shadow: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: max-content;
      gap: 4px;
      color: rgb(0 0 0 / 45%);

      &.active {
        color: #4096ff !important;
      }

      > div {
        width: 100%;
      }

      &.active {
        color: #000000e0;
      }

      span {
        margin-inline-start: 0 !important;
        font-size: 12px;
        line-height: 12px;
        font-weight: 400;
      }

      svg {
        font-size: 22px;
      }
    }
  }
}
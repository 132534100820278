.topics {
  width: 648px !important;

  .content-wrapper {
    > .title {
      margin-bottom: 24px;

      .ant-card-body {
        display: flex;

        .left {
          margin-right: 24px;

          .logo {
            img {
              width: 64px;
              height: 64px;
              border-radius: 5px;
              border: 2px solid rgba(0, 0, 0, 0.2901960784);
            }
          }
        }

        .right {
          h1 {
            margin-top: 0;
            margin-bottom: 16px;
            font-size: 36px;
            line-height: 36px;
            color: #2d3846;
          }

          p {
            margin-top: 0;
            color: #6b7f99;
            font-weight: 500;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 1.4;
          }
        }
      }
    }

    .content {
      .ant-collapse { //ant design collapse component customize ettiğimiiz yer
        background-color: transparent;

        .ant-collapse-content-box {
          padding: 0;
        }

        .ant-collapse-header {
          padding-left: 5px;
          padding-right: 0;
          padding-bottom: 20px;
        }

        .ant-collapse-expand-icon {
          order: 2;

          span {
            font-size: 20px;
          }
        }

        .ant-collapse-header-text {
          display: flex;
          align-items: center;
          font-size: 20px;
          font-weight: 500;
          color: #2d3846;

          svg {
            margin-right: 15px;
            color: #149ef2;
            font-size: 26px;
          }
        }
      }

      .panel-container {
        .topic {
          width: 100%;
          margin-bottom: 8px;
          position: relative;

          &.locked {
            opacity: 0.5;

            .top {
              .status {
                svg {
                  font-size: 26px;
                  color: #444444;
                }
              }
            }
          }

          .top {
            display: flex;

            .icon {
              margin-top: 30px;
              margin-right: 16px;
              color: #149ef2;
              font-size: 32px;
            }

            .texts {
              display: flex;
              flex-direction: column;
              gap: 8px;
              flex: 1;

              .type {
                color: #6b7f99;
                font-size: 12px;
                font-weight: 400;
                letter-spacing: .01em;
                line-height: 1.3
              }

              .title {
                color: #2d3846;
                font-family: Fira Sans, sans-serif;
                font-size: 20px;
                font-weight: 600;
                letter-spacing: -0.01em;
                line-height: 1.4;
              }

              .xp {
                color: #6b7f99;
                font-size: 12px;
                letter-spacing: .01em;
                line-height: 1.3;
                padding: 4px 8px;
                width: max-content;
                border: 1px solid #c8d2db;
                border-radius: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }

            .status {
              position: absolute;
              top: 10px;
              right: 10px;

              svg {
                font-size: 42px;
                color: #40af0c;
              }
            }
          }

          .button {
            margin-top: 16px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}

.main-mobile {
  .topics {
    .content-wrapper {
      .content {
        margin-top: 20px;

        .ant-collapse {
          .ant-collapse-header-text {
            font-size: 15px;

            svg {
              font-size: 24px;
            }
          }

          .ant-card-body {
            padding: 16px 24px;
          }
        }

        .panel-container {
          .topic {
            .top {
              .icon {
                font-size: 28px;
              }

              .texts {
                gap: 5px;

                .title {
                  font-size: 16px;
                }

                .desc {
                  font-size: 13px;
                  line-height: normal;
                }
              }
            }

            button {
              font-size: 14px;
              height: 38px;
            }
          }
        }
      }
    }
  }
}
.daily-goals {
  background-color: white;
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  box-shadow: 0 0 11px -6px #0000001a;

  .title {
    border-bottom: 1px solid #efefef;
    padding: 15px;
    display: flex;
    justify-content: space-between;

    .text {
      font-size: 15px;

      span {
        color: #0000008c;
        font-size: 14px;
        margin-left: 6px;

        b {
          font-weight: normal;
          color: black;
        }
      }
    }
  }

  .content {
    padding: 40px 40px 0 40px;

    .ant-timeline-item-head {
      font-size: 20px;
    }

    .info {
      background-color: #f2f5f7;
      border-radius: 5px;
      color: #424242;
      border: 1px solid rgba(225, 225, 225, 0.431372549);
      margin-top: 5px;
      padding: 5px 10px;
      font-size: 13px;
      width: max-content;
      cursor: pointer;
      display: block;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.main-mobile {
  .daily-goals {
    .title {
      padding: 15px 25px;

      .text{
        font-weight: 500;
      }
    }

    .content {
      padding-top: 30px;
    }
  }
}

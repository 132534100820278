.statistics {
  width: 100%;
  min-height: 300px;
  background-color: white;
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  box-shadow: 0 0 11px -6px rgba(0, 0, 0, 0.1019607843);

  .content-wrapper {
    padding: 30px 30px 80px 30px;

    .title {
      font-size: 23px;
      font-weight: bold;
      margin-bottom: 40px;
      opacity: 0.8;
      color: #6b7f99;
    }

    .content {
      display: flex;
      justify-content: center;
      gap: 50px;

      .item {
        width: 280px;

        .item-wrapper {
          gap: 20px;
          display: flex;
          align-items: center;
          justify-content: center;

          .text {
            .left {
              h6 {
                font-size: 18px;
                opacity: 0.9;
              }

              p {
                opacity: 0.8;
              }
            }
          }
        }
      }
    }
  }
}

.main-mobile {
  .statistics {
    width: 400px;
    margin: 0 auto;
    background-color: transparent;
    border: none;
    box-shadow: none;

    .content-wrapper {
      padding: 0;

      .title {
        font-size: 20px;
        margin-bottom: 20px;
      }

      .content {
        gap: 15px;

        .item {
          background-color: white;
          width: 100%;
          padding: 25px;
          box-shadow: 0 0 11px -6px rgba(0, 0, 0, 0.1019607843);
          border-radius: 12px;

          &:nth-of-type(1) {
            .left h6, .right svg {
              color: #1CC1F1;
            }

            .ant-progress-bg {
              background-color: #1CC1F1;
            }
          }

          &:nth-of-type(2) {
            .left h6, .right svg {
              color: #F6A831;
            }

            .ant-progress-bg {
              background-color: #F6A831;
            }
          }

          &:nth-of-type(3) {
            .left h6, .right svg {
              color: #01B650;
            }

            .ant-progress-bg {
              background-color: #01B650;
            }
          }

          .item-wrapper {
            justify-content: normal;
            align-items: normal;
            flex-direction: column;

            .ant-progress-text {
              font-size: 13px;
              position: relative;
              top: 5px;
            }

            .ant-progress-line {
              margin-bottom: 0;
              display: flex;
            }

            .text {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding-right: 7px;

              .left {
                h6 {
                  font-size: 20px;
                  margin-bottom: 5px;
                }

                p {
                  font-size: 13px;
                }
              }

              .right {
                svg {
                  font-size: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.patterns-learn {
  max-width: 100%;
  width: 740px;
  position: relative;
  padding-top: 50px;
  margin: 0 auto;

  .content-wrapper {
    .content {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      .pattern {
        background-color: white;
        width: 360px;
        height: 160px;
        border-radius: 10px;
        padding: 20px;
        font-size: 17px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: 0 0 1px #0000002b;


        .top {
          .mean {
            color: #767676;
          }
        }

        .bottom {
          .sound {
            margin-left: auto;
            background-color: #f2f5f7;
            border-radius: 100%;
            width: 50px;
            height: 50px;
            padding: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgb(0 0 0 / 72%);
            font-size: 62px;
            cursor: pointer;

            &:hover {
              background-color: #eaf2f7;
            }
          }
        }
      }
    }
  }
}

.main-mobile {
  div.patterns-learn {
    .content-wrapper {
      .content {

        .pattern {
          height: max-content;
        }
      }
    }
  }
}
body {
  margin: 0;
  color: rgb(33, 43, 54);
  line-height: 1.5;
  font-size: 1rem;
  font-family: "Public Sans", sans-serif;
  font-weight: 400;
  background-color: #f2f5f7;
  overflow-x: hidden;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  max-width: 100%;
}

.custom-container {
  width: 900px;
  max-width: 100%;
  margin: 0 auto;
}

.swal2-styled.swal2-confirm {
  background-color: #1677ff !important;
}

.wt-spin {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: max-content;
  height: max-content;
  z-index: 999;
  margin: auto;
}

.main-mobile {
  .app-content {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 50px;
    padding-bottom: 100px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.patterns {
  max-width: 100%;
  position: relative;
  padding-left: 60px;
  padding-right: 60px;
  margin: 0 auto;
  width: 940px;

  .content-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;

    .part {
      background-color: white;
      flex: 45%;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      height: 100px;
      align-items: center;
      padding: 30px;
      box-shadow: 0 0 1px #0000004a;
      text-decoration: none;
      color: inherit;

      &:hover {
        .left {
          .circle {
            background-color: #3171EC;
          }
        }
      }

      .left {
        .circle {
          background-color: #C6C6CA;
          width: 20px;
          height: 20px;
          border-radius: 100%;
        }
      }

      .middle {
        margin-left: 15px;


        .level {
          font-weight: 600;
        }

        .info {
          font-weight: 300;
          font-size: 14px;
          color: #484847
        }
      }

      .right {
        margin-left: auto;

        .ant-progress-inner {
          width: 70px !important;
          height: 70px !important;
          font-size: inherit !important;
        }
      }
    }
  }
}

.partModal {
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 10px;

    a {
      width: 100%;
    }

    button {
      width: 100%;
      height: 40px;
    }
  }
}

.main-mobile {
  .patterns {
    top: 0;
    padding-left: 0;
    padding-right: 0;

    .content-wrapper {
      padding-top: 0;
      padding-right: 0;
      padding-left: 0;
      gap: 10px;
      flex-direction: column;

      .part {
        border-radius: 12px;
        width: 100%;

        .middle {
          .level {
            font-size: 14px;
          }

          .info {
            font-size: 13px;
          }
        }

        .right {
          font-size: 13px;
        }
      }
    }
  }
}
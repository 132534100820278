.banner {
  background-color: #5624d0;
  width: 100%;
  min-height: 76px;
  line-height: 1.75rem;
  padding: 1.5rem 2rem;
  text-align: center;
  position: relative;
  color: white;

  .close {
    position: absolute;
    height: 40px;
    width: 60px;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    font-size: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.header {
  width: 100%;
  height: 112px;
  background-color: #f9f9fa;
  border-bottom: 1px solid rgba(245, 245, 245, 0.7882352941);
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #0000000d;

  > .content-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1220px;
    padding: 0px 40px;
    height: 100%;

    .left {
      a {
        color: black;
        text-decoration: none;
        font-size: 36px;
        display: flex;
        align-items: center;
        font-weight: 600;
        gap: 8px;
        letter-spacing: -1px;

        img {
          width: 54px;
        }
      }
    }

    .middle {
      ul {
        width: 100%;
        border: none !important;
        display: flex;
        align-items: center;

        li {
          list-style: none;
          margin-right: 30px;

          &:nth-last-of-type(1) {
            margin-right: 0;
          }

          a {
            text-decoration: none;
            color: #2d3846;
            font-size: 20px;
            height: max-content;

            &.active, &:hover {
              color: #189eff;
            }

            &.ant-btn {
              font-size: 18px;
            }

            button {
              font-size: 18px;
              height: 43px;

              > div {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
div.others-menus {
  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .profile {
      margin-bottom: 0;
      background-color: transparent;
      box-shadow: none;
    }

    .menus {
      background-color: white;
      width: 500px;

      ul {
        display: flex;
        flex-direction: column;

        li {
          list-style: none;
          border-bottom: 1px solid #e9e9e9;

          a {
            text-decoration: none;
            width: 100%;
            display: block;
            font-size: 14px;
            padding: 15px;
            color: #2d3846;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

body.others-menus {
  .app-content {
    padding-left: 0;
    padding-right: 0;
  }
}


body.lesson {
  background-color: white;
  overflow: hidden;

  .header {
    display: none;
  }

  .main-content {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
}

div.lesson {
  max-width: 100%;
  position: relative;
  top: -50px;

  padding-top: 100px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100vh;

  .top {
    margin-bottom: 50px;
    width: 765px;
    margin: 0 auto;

    .info {
      display: flex;
      align-items: center;
      color: #6b7f99;
      margin-bottom: 20px;

      svg {
        font-size: 22px;
        margin-right: 6px;
        cursor: pointer;
      }
    }

    .progress-bar {
      width: 100%;

      .ant-progress {
        width: 100%;

        .ant-progress-steps-item {
          flex: 1;
        }

        .ant-progress-steps-item-active {
          background-color: #40af0c;
        }
      }
    }
  }

  .middle {
    overflow-y: auto;
    height: 73vh;
    margin-bottom: 75px;

    .content {
      width: 730px;
      margin: 15px auto 0 auto;
      display: flex;
      flex-direction: column;
      gap: 15px;

      ul, ol {
        padding-left: 40px;
      }
    }
  }

  .bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 113px;
    border-top: 1px solid #c8d2db;
    background-color: white;

    .content {
      max-width: 648px;
      padding: 32px 0;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      .ant-btn {
        width: 161px;
        height: 48px;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  &.completed {
    .middle {
      width: 100%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      align-items: center;

      .content {
        width: 368px;

        img {
          width: 230px;
          margin: 0 auto;
        }

        p {
          color: #6b7f99;
          font-size: 18px;
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }

    .bottom {
      .content {
        justify-content: center;
        gap: 25px;

        .go-tests-btn {
          font-weight: initial;
        }

        button {
          width: max-content;
        }
      }
    }
  }

}

body.lesson {
  .app-content {
    width: 100% !important;
  }
}

.main-mobile {
  div.lesson {
    margin-top: 3vh;
    padding: 0;

    .top {
      .progress-bar {
        margin-bottom: 2vh;
      }
    }

    .ant-progress-text {
      text-align: right;
    }

    .middle {
      .content {
        font-size: 13px;

        img {
          width: 170px;
          margin: 0 auto;
        }

        ul, ol {
          padding-left: 20px;
        }
      }
    }

    .bottom {
      z-index: 3;
      height: max-content;

      .content {
        padding: 10px;
        display: flex;
        justify-content: center;
        gap: 15px;

        .ant-btn {
          width: 50%;
          height: 48px;
          font-size: 13px;
        }
      }
    }
  }
}
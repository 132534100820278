.education {
  .pro-banner {
    align-items: center;
    background-color: #000a23;
    border-radius: 8px;
    display: flex;
    height: 96px;
    justify-content: space-between;
    padding: 24px 32px;
    position: relative;

    img {
      width: 90px;
      position: relative;
      top: 2px;
    }

    .left {
      .title {
        color: #fff;
        font-size: 19px;
        margin-bottom: 8px;
        height: max-content;
        line-height: 19px;

        span {
          background: #0059fb;
          border-radius: 100px;
          font-size: 13px;
          margin-left: 3px;
          padding: 2px 5px
        }
      }

      .desc {
        color: #fff;
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
      }
    }

    .right {
      button {
        background: #0059fb;
        padding: 0 48px;
        height: 48px;
        font-weight: 600;
        font-size: 13px;
      }
    }
  }

  .get-started {
    padding-bottom: 25px;
    background-color: white;
    margin-top: 30px;
    border-radius: 8px;
    border: 1px solid #f3f3f3;
    box-shadow: 0 0 11px -6px #0000001a;

    .content-wrapper {
      padding-top: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        h1 {
          font-size: 22px;
          font-weight: 600;
          color: #484848;
        }

        h3 {
          font-size: 14px;
          font-weight: 300;
          margin-top: 10px;
          color: #484848;
        }
      }

      .content {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 15px;

        > div {
          flex: 1 1;
          padding: 25px 15px;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          height: 368px;
          overflow: hidden;
          position: relative;
          justify-content: space-between;

          h2 {
            font-size: 17px;
            font-weight: 600;
            margin-bottom: 20px;
            color: #0275B8;
          }

          p {
            margin-bottom: 30px;
            padding: 0;
            font-size: 15px;
            font-weight: 300;
            line-height: 20px;
          }

          .icon {
            background-color: #f1f1f191;
            border-radius: 50%;
            height: 100px;
            width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              width: 55px;
              height: 55px;
              opacity: 0.8;
              color: #007eff;
            }
          }

          button {
            margin-top: 30px;
            background-color: #4DB538;
            padding: 0 24px;
            position: relative;
            z-index: 2;


            &:hover {
              filter: saturate(1.6);
            }
          }
        }

        .middle {
          background: #0059fb;
          box-shadow: 0px 2px 8px rgb(0 0 0 / 20%);
          border-radius: 12px;
          color: #fff;

          h2 {
            color: inherit;
          }

          .icon {
            background-color: #189eff;

            svg {
              color: white;
            }
          }

          img {
            width: 510px;
            opacity: 0.4;
            position: absolute;
            bottom: -120px;
          }
        }
      }
    }
  }
}

.main-mobile {
  .daily-goals {
    .title {
      .text {
        font-size: 13px;
      }
    }

    .ant-timeline-item {
      &:nth-last-of-type(1) {
        padding-bottom: 0;
      }
    }

    .ant-timeline-item-content {
      font-size: 13px;
    }
  }

  .education {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    .pro-banner {
      padding: 20px;
      gap: 15px;

      img {
        display: none;
      }

      .left {
        .title {
          font-weight: bold;
        }

        .desc {
          font-size: 12px;
        }
      }
    }

    .get-started {
      background-color: transparent;
      box-shadow: none;
      border: none;

      .content-wrapper {
        padding-top: 0;
        background: transparent;

        .title {
          display: none;
        }

        .content {
          flex-direction: column;
          margin-top: 0;
          gap: 30px;

          .left, .right {
            color: rgba(0, 0, 0, 0.88);
            background-color: white;
          }

          .middle {
            h2 {
              color: white;
            }
          }

          > div {
            box-shadow: 0 0 11px -6px rgba(0, 0, 0, 0.1019607843);
            border-radius: 12px;
            padding: 30px 25px;
            gap: 10px;

            img {
              width: 230px;
              height: 153px;
              object-fit: contain;
              position: unset;
              opacity: 1;
            }

            a {
              width: 100%;

              button {
                margin-top: 15px;
                width: 100%;
                background-color: #EFF4FA;
                color: #315669;
                font-weight: 400;
                border-radius: 18px;
                font-size: 14px;
              }
            }

            p {
              margin-bottom: 0;
              line-height: normal;
              font-size: 12px;
              text-align: left;
              font-weight: 300;
            }

            h2 {
              margin-bottom: 0;
              color: #646464;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
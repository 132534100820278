.dashboard {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .container {
    margin-top: 30px;
    display: flex;
    gap: 30px;
    width: 1136px;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;

    > .left {
      width: 340px;
    }

    > .right {
      flex: 1;
    }

    > .bottom {
      width: 100%;
      margin-bottom: 100px;
      gap: 40px;
      display: flex;
      flex-direction: column;
    }
  }

  .container-fluid {
    width: 100%;
  }
}

.main-mobile {
  .dashboard {
    .container {
      align-items: center;
      flex-direction: column;
      width: 400px;
      margin-top: 25px;

      > .left {
        width: 100%;
      }
    }


    .container-v2 {
      width: 100%;
      height: 800px;
      display: flex;
      flex-direction: column;
      gap: 25px;
      margin-top: 25px;
      margin-bottom: 100px;

      .bottom {
        .main-education {
          background-color: white;
          border: 1px solid #f3f3f3;
          border-radius: 8px;
          box-shadow: 0 0 11px -6px rgba(0, 0, 0, 0.1019607843);
          overflow: hidden;
          padding: 25px;

          .title {
            width: 100%;
            font-weight: 500;
            padding-bottom: 25px;
          }

          .content {
            display: flex;
            flex-direction: column;

            .item {
              display: flex;
              align-items: center;
              gap: 15px;
              padding: 25px 0;
              border-top: 1px solid #efefef;
              text-decoration: none;
              color: black;

              &:nth-last-of-type(1) {
                padding-bottom: 0;
                border-bottom: none;
              }

              .icon-box {
                width: 60px;
                height: 60px;
                border-radius: 10px;
                border: 1px solid #c9c9c9;
                display: flex;
                font-size: 45px;
                align-items: center;
                justify-content: center;
                background-color: #eef6f9;
                color: #32667C;
                overflow: hidden;
                padding: 5px;

                img {
                  object-fit: contain;
                  width: 100%;
                  height: 100%;
                }
              }

              .text {
                flex: 1;

                h4 {
                  font-size: 15px;
                }

                p {
                  font-size: 12px;
                }
              }

              .custom-button {
                background-color: #EEF6F9;
                color: #32667C;
                width: 40px;
                height: 40px;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 23px;

                &:hover {
                  filter: saturate(3);
                }
              }
            }
          }
        }
      }
    }
  }

}
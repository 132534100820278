body.tests-make {
  .header {
    display: none;
  }
}

div.tests-make {
  max-width: 100%;
  position: relative;
  margin: 0 auto;
  width: 500px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;

  .top {
    .info {
      display: flex;
      align-items: center;
      color: #6b7f99;
      margin-bottom: 20px;

      svg {
        font-size: 22px;
        margin-right: 6px;
        cursor: pointer;
      }
    }

    .progress-bar {
      width: 100%;

      .ant-progress {
        width: 100%;

        .ant-progress-steps-item {
          flex: 1;
        }

        .ant-progress-steps-item-active {
          background-color: #40af0c;
        }
      }
    }
  }

  .bottom {
    margin: 0 auto;
    border-radius: 10px;
    padding: 15px;

    .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .question {
        width: 100%;
        background-color: white;
        padding: 20px;
        font-size: 15px;
        border-radius: 5px;
      }

      .answers {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;
        margin-top: 30px;

        > div {
          background-color: white;
          padding: 15px 15px 15px 60px;
          border-radius: 5px;
          position: relative;
          cursor: pointer;

          &.true-answer {
            background-color: #00ff1f0d;
            border: 1px solid #00ff2b5e;
          }

          &.false-answer {
            background-color: #ff00000d;
            border: 1px solid #ff000033;
          }

          &:hover, &.active {
            &:before {
              background-color: #3171EC;
            }
          }

          &:before {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            top: 0;
            bottom: 0;
            margin: auto;
            border: 1px solid #C6C6CA;
            left: 20px;
          }
        }
      }

      .ant-btn {
        width: 100%;
        min-width: 161px;
        height: 48px;
        font-size: 16px;
        font-weight: 600;
        margin-top: 30px;
      }

    }
  }


  &.completed {
    .middle {
      width: 100%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      align-items: center;

      .content {
        width: 368px;

        img {
          width: 230px;
        }

        h3 {
          font-size: 25px;
          margin-bottom: 15px;
        }

        p {
          color: #6b7f99;
          font-size: 16px;
          margin-top: 0;
          margin-bottom: 0;
        }
      }


      .counts {
        margin-top: 10px;
        display: flex;
        gap: 5px;
      }
    }

    .bottom {
      .content {
        justify-content: center;

        button {
          margin-top: 20px;
          width: max-content;
        }
      }
    }
  }

}

.main-mobile {
  div.tests-make {
    margin-top: 3vh;
    justify-content: normal;
    height: auto;

    .top {
      .progress-bar .ant-progress {
        margin-bottom: 2vh;
      }

      .info {
        display: none;
      }
    }

    .bottom {
      padding: 0;

      .question {
        font-size: 13px;
      }

      .answers {
        margin-top: 20px;
        gap: 10px;

        > div {
          font-size: 13px;
        }
      }

      .ant-btn {
        margin-top: 20px;
      }
    }

    &.completed {
      .content-wrapper {
        .middle {
          .content {
            img {
              width: 170px;
            }

            h3 {
              font-size: 18px;
              margin-bottom: 10px;
            }

            p {
              font-size: 13px;
            }
          }
        }

        .bottom {
          .content {
            button {
              width: 50%;
              height: 44px;
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}
.wt-head {
  background-color: white;
  width: 100%;
  height: max-content;
  padding: 45px 30px 45px 45px;
  display: flex;
  gap: 30px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 1px;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 50px;

  .left {
    width: 170px;

    img {
      width: 400px;
      height: 130px;
      object-fit: contain;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .title {
      color: rgb(64, 64, 64);
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 0.8px;
    }

    .count {
      .test {
        font-weight: 500;
        font-size: 13px;

        span {
          margin-right: 5px;
          background-color: #009dff;
          color: white;
          padding: 3px 4px;
        }
      }
    }

    .desc {
      color: rgb(120, 120, 120);
      font-size: 16px;
      letter-spacing: 0.8px;
    }
  }
}

.main-mobile {
  .wt-head {
    height: max-content;
    padding: 20px;
    gap: 20px;
    border-radius: 12px;
    margin-top: 20px;
    margin-bottom: 20px;

    .left {
      display: none;

      img {
        width: 300px;
      }
    }

    .right {
      gap: 2px;

      .desc {
        font-size: 12px;
      }
    }
  }
}
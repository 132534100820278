.top-navigation-bar {
  width: 100%;
  background-color: white;
  position: fixed;
  top: 0;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: space-evenly;
  z-index: 2;
  box-shadow: 0 0 4px 0px rgba(143, 143, 143, 0.25);


  .back-button {
    position: absolute;
    border: none;
    box-shadow: none;
    height: max-content;
    color: rgba(0, 0, 0, 0.8784313725);
    display: flex;
    width: max-content;
    left: 5px;
    padding: 11px;

    svg {
      font-size: 22px;
    }
  }
}
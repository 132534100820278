.levels {
  width: 700px !important;

  .top-title {
    margin-top: 25px;

    p {
      font-size: 16px;
      font-weight: 300;
    }
  }

  .content-wrapper {
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;

    .level {
      box-shadow: 0 0 11px -6px rgba(0, 0, 0, 0.1019607843);
      border-radius: 5px;
      gap: 10px;
      color: rgba(0, 0, 0, 0.88);
      background-color: white;
      padding: 30px 25px;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
      position: relative;
      width: 48%;
      justify-content: space-between;
      line-height: 21px;
      text-align: center;

      .left {
        width: 100%;

        .wrapper {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .logo {
            width: auto;
            height: auto;
            padding: 0;
            background-color: transparent;
            border-radius: 0;

            img {
              width: 230px;
              height: 153px;
              object-fit: contain;
            }
          }

          .info {
            margin-left: 0;
            width: 100%;

            .top {
              display: flex;
              flex-direction: column;
              gap: 10px;

              .title {
                margin-bottom: 0;
                color: #646464;
                font-weight: bold;
                font-size: 18px;
              }

              .desc {
                margin-bottom: 0;
                line-height: normal;
                font-size: 15px;
                font-weight: 300;
                color: #7c7c7c;
                text-align: center;
              }
            }
          }
        }
      }

      .right {
        width: 100%;

        button {
          margin-top: 10px;
          width: 100%;
          font-weight: 400;
          border-radius: 18px;
          font-size: 16px;
        }
      }
    }
  }
}

.main-mobile {
  .levels {
    .content-wrapper {
      flex-direction: column;

      .level {
        width: 100%;
        border-radius: 12px;

        .left {
          width: 100%;

          .wrapper {
            .info {
              .top {
                .title {
                  font-size: 17px;
                }

                .desc {
                  font-size: 12px;
                }
              }
            }
          }
        }

        .right {
          button {
            font-size: 14px;
          }
        }
      }
    }
  }
}
body.vocabularies-learn {
  overflow: hidden;

  .header {
    display: none;
  }
}

div.vocabularies-learn {
  .content-wrapper {
    max-width: 100%;
    position: relative;
    margin: 0 auto;
    width: 500px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: 5vh;
    justify-content: center;

    .top {
      margin-bottom: 25px;

      .info {
        display: flex;
        align-items: center;
        color: #6b7f99;
        margin-bottom: 20px;

        svg {
          font-size: 22px;
          margin-right: 6px;
          cursor: pointer;
        }
      }

      .progress-bar {
        width: 100%;

        .ant-progress {
          width: 100%;

          .ant-progress-steps-item {
            flex: 1;
          }

          .ant-progress-steps-item-active {
            background-color: #40af0c;
          }
        }
      }
    }

    .middle {
      margin: 0 auto;
      background-color: #ffffff;
      height: 500px;
      border-radius: 10px;
      padding: 15px;
      width: 100%;

      .content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;


        &.active {
          transition: all 700ms;

          transform: rotateY(180deg);

          .word-wrapper, .sound {
            transform: rotateY(-180deg);
            transition-delay: 200ms;

            .mean {
              transition-delay: 250ms;
              visibility: visible !important;
            }
          }

        }


        .word-wrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          font-weight: 500;

          hr {
            width: 80%;
            border-color: #0000000d;
          }

          .word {
            font-size: 28px;
          }

          .mean {
            margin-top: 10px;
            color: #727272;
            font-size: 22px;

            &.hidden {
              visibility: hidden;
            }
          }
        }

        .sound {
          margin-top: 100px;
          background-color: #f2f5f7;
          border-radius: 100%;
          width: 130px;
          height: 130px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgb(0 0 0 / 72%);
          font-size: 62px;
          cursor: pointer;
          margin-bottom: 30px;

          &:hover {
            background-color: #eaf2f7;
          }
        }

      }
    }

    .bottom {
      min-height: 175px;

      .content {
        max-width: 648px;
        padding: 32px 0;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        gap: 15px;
        flex-wrap: wrap;


        .okay {
          background-color: #52c41a;
        }

        .ant-btn {
          min-width: 161px;
          height: 48px;
          font-size: 16px;
          font-weight: 600;
        }

        .skip, .okay {
          width: 45%;
        }

        .notOkay {
          width: 93%;
          background-color: #f44336;
        }
      }
    }
  }

  &.completed {
    width: max-content;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    height: max-content;
    bottom: 0;

    .middle {
      width: 100%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      align-items: center;
      margin-top: 100px;

      .content {
        width: 368px;

        img {
          width: 230px;
        }

        h3 {
          font-size: 25px;
          margin-bottom: 15px;
        }

        p {
          color: #6b7f99;
          font-size: 16px;
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }

    .bottom {
      text-align: center;
      margin-top: 20px;

      .content {
        justify-content: center;

        button {
          width: max-content;
        }
      }
    }
  }

}

.main-mobile {
  div.vocabularies-learn {
    padding-top: 0;
    justify-content: normal;
    height: max-content;
    margin-top: 3vh;

    .content-wrapper {
      padding-top: 0;
      height: max-content;

      .top {
        margin-bottom: 10px;

        .info {
          display: none;
        }
      }

      .middle {
        height: 45vh;

        .content {
          height: 100%;

          .sound {
            margin: auto;
            width: 100px;
            height: 100px;
            font-size: 42px;
          }

          .word-wrapper {
            .word {
              font-size: 24px;
            }

            .mean {
              font-size: 20px;
            }
          }
        }
      }

      .bottom {
        min-height: auto;

        .content {
          padding: 0;
          margin-top: 3vh;

          .ant-btn {
            flex: 1;
            min-width: auto;
            flex-basis: 30%;
            font-size: 13px;


            &.okay, &.skip {
              width: 161px;
            }

            &.notOkay {
              flex-basis: 100% !important;
            }
          }
        }
      }
    }

    &.completed {
      height: 80vh;

      .middle {
        height: max-content;
        padding: 30px 25px;

        .content {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;

          img {
            width: 170px;
          }

          h3 {
            font-size: 19px;
          }

          p {
            font-size: 14px;
          }
        }
      }

      .bottom {
        text-align: center;

        button {
          height: 48px;
          font-size: 13px;
        }
      }
    }
  }
}
.profile {
  margin-bottom: 20px;
  background-color: white;
  padding: 40px 40px 30px 40px;
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  box-shadow: 0 0 11px -6px #0000001a;

  .content {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;

    .avatar-container {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-direction: column;

      .info {
        display: flex;
        flex-direction: column;
        align-items: center;

        .name {
          font-size: 23px;
          font-weight: 700;
        }
      }


      .avatar {
        width: 95px !important;
        height: 95px !important;
        font-size: 41px !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .profile-button {
        font-weight: 600;
        text-decoration: none;
        color: inherit;

        &:hover {
          text-decoration: underline;
        }
      }

      .desc {
        background-color: #f2f5f7;
        padding: 10px;
        text-align: center;
        font-size: 14px;
        border-radius: 5px;
        color: #424242;
        border: 2px solid #e1e1e16e;
      }

      .member-since {
        font-size: 13px;
      }
    }


  }
}
.statistics-page {
  margin-top: 30px;
  display: flex;
  gap: 30px;
  width: 1136px;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
}

.main-mobile {
  .statistics {
    .content-wrapper {
      .content {
        flex-direction: column;
      }
    }
  }
}
div.start-learn {
  width: 1000px;
  margin-bottom: 50px;

  .content-wrapper {
    .content {
      background-color: white;
      box-shadow: 0 0 11px -6px #0000001a;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 75px;
      border-radius: 5px;

      .left {
        img {
          width: 380px;
          object-fit: contain;
        }
      }

      .right {
        ul {
          display: flex;
          flex-direction: column;
          gap: 18px;

          li {
            list-style: none;
            display: flex;
            align-items: center;
            gap: 5px;
            font-weight: 300;
            font-size: 17px;

            svg {
              font-size: 18px;
              margin-right: 5px;
            }

            b {
              font-weight: 600;
            }
          }
        }

        button {
          margin-top: 30px;
          height: max-content;
          width: 160px;
          padding: 10px 0;
          font-size: 18px;
          font-weight: 600;
        }
      }
    }

    .info {
      text-align: center;
      opacity: 0.7;
      width: 80%;
      margin: 40px auto 0 auto;
      font-size: 14px;
    }
  }
}

body.start-learn {
  .main-mobile {
    div.start-learn {
      .wt-head {
        display: none;
      }
    }
  }
}

.main-mobile {
  div.start-learn {
    margin-top: 20px;

    .content-wrapper {
      .content {
        flex-direction: column;
        padding: 25px;
        gap: 20px;
        border-radius: 12px;

        .left {
          img {
            width: 200px;
          }
        }

        .right {
          a {
            width: 100%;
            display: block;
            text-align: center;

            button {
              width: 100%;
              font-size: 14px;
              width: 100%;
              font-weight: 400;
              border-radius: 18px;
              height: 40px;
            }
          }

          ul {
            li {
              font-size: 13px;
              display: block;

              svg {
                font-size: 15px;
              }

              b {
                font-size: 13px;
                font-weight: 500;
              }
            }
          }
        }
      }

      .info {
        margin-top: 20px;
        width: 100%;
        font-size: 12px;
      }
    }
  }
}
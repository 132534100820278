.community {
  background-color: white;
  padding-top: 120px;
  padding-bottom: 120px;

  .content-wrapper {
    display: flex;
    justify-content: center;

    .content {
      display: flex;
      width: 1260px;
      justify-content: center;
      gap: 85px;

      .img-wrapper {
        width: 678px;
      }

      .desc {
        width: 380px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h2 {
          font-size: 46px;
          font-weight: 800;
          line-height: 60px;
        }

        p {
          margin-top: 24px;
          font-size: 19px;
          line-height: 31px;
          margin-bottom: 15px;
        }

        button {
          width: 200px;
          margin-top: 15px;
        }
      }
    }
  }
}

.main-mobile {
  .community {
    padding: 20px 15px;
    height: 100vh;

    .content-wrapper {
      .content {
        display: flex;
        flex-direction: column;
        gap: 40px;

        .desc {
          width: 100%;

          h2 {
            font-size: 24px;
            line-height: normal;
          }

          p {
            margin-top: 10px;
            font-size: 14px;
            line-height: 24px;
          }
        }

        .img-wrapper {
          order: -1;

          img {
            width: 100%;
          }
        }
      }
    }
  }
}
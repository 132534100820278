.feedback {
  width: 100px;

  .ant-modal-content {
    width: 100%;
    padding: 50px;

    h3 {
      text-align: center;
      font-size: 18px;
      line-height: 22px;
      color: #2d3846;
    }

    .points {
      display: flex;
      justify-content: space-evenly;
      margin-top: 20px;

      > div {
        padding: 15px;
        cursor: pointer;

        &:hover, &.active {
          border-radius: 10px;
          box-shadow: 0 0 10px -3px #0000003b;
        }

        svg {
          font-size: 84px;
        }
      }
    }

    .comment, .categories {
      display: flex;
      flex-direction: column;
      gap: 10px;

      > label {
        font-size: 15px;
        font-weight: 600;
        color: #2d3846;
      }
    }


    .comment {
      margin-top: 20px;
    }

    .categories {
      .ant-form-item-control-input {
        min-height: max-content;
      }
    }

    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;

      button {
        width: 190px;
        height: 50px;
        font-size: 18px;
      }
    }
  }
}

.global-info-btn {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 2;
  border: 1px solid #ffffff45;
}

.main-mobile {
  .global-info-btn {
    bottom: 80px;
  }
}

@media screen and (max-width: 767px) {
  .feedback {
    .ant-modal-content {
      padding: 50px 40px;

      h3 {
        font-size: 14px;
      }

      .points {
        > div {
          padding: 15px;
          cursor: pointer;
          width: 100px;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .ant-modal-footer {
        gap: 10px;

        button {
          font-size: 14px;
        }
      }
    }
  }
}
.tests {
  max-width: 100%;
  position: relative;
  padding-left: 60px;
  padding-right: 60px;
  margin: 0 auto;
  width: 940px;

  .content-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .content {
      background-color: white;
      width: 100%;
      height: max-content;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 1px;

      .test {
        display: flex;
        justify-content: space-between;
        padding: 15px 15px 15px 30px;
        border-top: 1px solid #f2f2f2;
        font-size: 15px;
        align-items: center;
        text-decoration: none;
        cursor: pointer;
        opacity: 0.5;

        &.active {
          opacity: 1;
        }

        &:hover {
          background-color: #f9f9f9;
        }

        &:nth-of-type(1) {
          border: none;
        }

        .name {
          color: rgb(64, 64, 64);
          font-weight: 600;
          margin-left: 20px;
        }

        .number {
          color: rgb(184, 184, 184);
          letter-spacing: 0.8px;
          font-weight: 300;
          display: flex;

          svg{
            font-size: 30px;
            height: 25px;
            width: 25px;
          }

          .success {
            color: #40af0c;
          }

          .fail {
            color: red;
          }
        }

        .result {
          display: flex;
          margin-left: auto;
          gap: 5px;
          color: black;
        }

        .action {
          display: flex;
          align-items: center;
          gap: 2px;
          margin-left: 20px;

          a {
            display: inherit;
            text-decoration: none;
            color: #303030;

            &:hover {
              cursor: pointer;
              text-decoration: underline;
            }

            svg {
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}

.main-mobile {
  div.tests {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    top: 0;

    .content {
      display: flex;
      flex-direction: column;
      gap: 10px;
      background-color: transparent;
      box-shadow: none;

      .test {
        padding: 20px 15px;
        background-color: white;
        border-radius: 12px;

        .number {
          font-size: 13px;
          display: none;
        }

        .name {
          font-size: 12px;
          margin-left: 0;
          flex: 1;
        }

        .result {
          gap: 2px;
          font-size: 13px;

          > div {
            span {
              .ant-scroll-number {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
}